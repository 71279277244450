
import { Fragment,useState,useRef,useEffect } from 'react'
import { Form ,useSubmit} from "react";

import axios from 'axios';
import {
    ArrowPathIcon,
    Bars3Icon,
    ChartBarIcon,
    CursorArrowRaysIcon,
    DocumentChartBarIcon,
    ShieldCheckIcon,
    Squares2X2Icon,
    XMarkIcon,
    CloudArrowUpIcon,
    FingerPrintIcon, 
    LockClosedIcon,
    WifiIcon,EnvelopeIcon, PhoneIcon,
  } from '@heroicons/react/24/outline'
  import ReCAPTCHA from "react-google-recaptcha"
  
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }


 
export default function FormHandling() {
    const recaptchaRef = useRef();
    const [getSent,setSent] = useState(false);

    const [getName,setName] = useState("Name");
    const [getMobile,setMobile] = useState("Test");
    const [getMessage,setMessage] = useState("Test");
    const [getSubject,setSubject] = useState("Test");
    const [getEmail,setEmail] = useState("Test");
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        // setInputs(values => ({...values, [name]: value}))
      }
      const recapChange=(e)=>{

      }
      // const handleSubmit = async (e) => {
        
      //   alert('sent');
      // }

      const handleSubmit = async (e) => {
  
            e.preventDefault();
            const FormData = require('form-data');
            const js = new FormData();


            js.append("name", "" + getName);
            js.append("mobile", "" + getMobile);
            js.append("subject", "" + getSubject);
            js.append("message", "" + getMessage);
            js.append("email", "" + getEmail);
            js.append("eck", "" + "B1AD65D6481EC92BF2B858B54EAA5");


            // js.append("catoke", "" + this.state.capttoken);
          try
          {

           axios.post('https://api.hotelshitalinn.com/Contact/Index1', js, {
                headers: {"Content-Type": "multipart/form-data"}
            });
                        const success = document.getElementById("success");
                        success.hidden=false;
                         document.getElementById("frm").reset()
          }
          catch
          {
            
          }


            // setSent(true);
            // const success = document.getElementById("success");
            // success.style="display:block";
            // const mail = document.getElementById("mail");
            // mail.style="display:none";



            // alert("Message Sent Successfully");
          
        
       

    }
    
    useEffect(() => {
      
      if(getName!="Name")
      {
        const success = document.getElementById("success");
        success.style="display:block";
        const mail = document.getElementById("mail");
        mail.style="display:none";

   
   
      }
     
      

    },[]);

    return(

        <>
        
        <div className="bg-gray-100" >
      <div className="mx-auto max-w-7xl py-16 px-6 sm:py-24 lg:px-8">
        <div className="relative bg-white shadow-xl">
          <h2 className="sr-only">Contact us</h2>

          <div className="grid grid-cols-1 lg:grid-cols-3">
            {/* Contact information */}
            <div className="relative overflow-hidden bg-sky-600 py-10 px-6 sm:px-10 xl:p-12">
              <div className="pointer-events-none absolute inset-0 sm:hidden" aria-hidden="true">
                <svg
                  className="absolute inset-0 h-full w-full"
                  width={343}
                  height={388}
                  viewBox="0 0 343 388"
                  fill="none"
                  preserveAspectRatio="xMidYMid slice"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z"
                    fill="url(#linear1)"
                    fillOpacity=".1"
                  />
                  <defs>
                    <linearGradient
                      id="linear1"
                      x1="254.553"
                      y1="107.554"
                      x2="961.66"
                      y2="814.66"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#fff" />
                      <stop offset={1} stopColor="#fff" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div
                className="pointer-events-none absolute top-0 right-0 bottom-0 hidden w-1/2 sm:block lg:hidden"
                aria-hidden="true"
              >
                <svg
                  className="absolute inset-0 h-full w-full"
                  width={359}
                  height={339}
                  viewBox="0 0 359 339"
                  fill="none"
                  preserveAspectRatio="xMidYMid slice"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z"
                    fill="url(#linear2)"
                    fillOpacity=".1"
                  />
                  <defs>
                    <linearGradient
                      id="linear2"
                      x1="192.553"
                      y1="28.553"
                      x2="899.66"
                      y2="735.66"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#fff" />
                      <stop offset={1} stopColor="#fff" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <div
                className="pointer-events-none absolute top-0 right-0 bottom-0 hidden w-1/2 lg:block"
                aria-hidden="true"
              >
                <svg
                  className="absolute inset-0 h-full w-full"
                  width={160}
                  height={678}
                  viewBox="0 0 160 678"
                  fill="none"
                  preserveAspectRatio="xMidYMid slice"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z"
                    fill="url(#linear3)"
                    fillOpacity=".1"
                  />
                  <defs>
                    <linearGradient
                      id="linear3"
                      x1="192.553"
                      y1="325.553"
                      x2="899.66"
                      y2="1032.66"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#fff" />
                      <stop offset={1} stopColor="#fff" stopOpacity={0} />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
              <h3 className="text-lg font-medium text-white">Contact information</h3>
              <p className="mt-6 max-w-3xl text-base text-indigo-50">
              We are glad to extend our warmest welcome and encourage you to enjoy the pinnacle of comfort and elegance at our hotel. At Hotel Shital Inn, we take pride in providing a seamless blend of contemporary luxury and traditional charm, ensuring that all of our esteemed guests have an amazing stay.

If you have any questions, comments, or would like to make a reservation, please contact our devoted team.  Please feel free to reach out to us using the following information:
              </p>
              <dl className="mt-8 space-y-6">
                <dt>
                  <span className="sr-only">Phone number</span>
                </dt>
                <dd className="flex text-base text-indigo-50">
                  <PhoneIcon className="h-6 w-6 flex-shrink-0 text-indigo-200" aria-hidden="true" />
                  <a href="tel:+917948001010" className="ml-3">+917948001010</a>
                </dd>
                <dd className="flex text-base text-indigo-50">
                  <PhoneIcon className="h-6 w-6 flex-shrink-0 text-indigo-200" aria-hidden="true" />
                  <a href="tel:+917949001010" className="ml-3">+917949001010</a>
                </dd>
                <dt>
                  <span className="sr-only">Email</span>
                </dt>
                <dd className="flex text-base text-indigo-50">
                  <EnvelopeIcon className="h-6 w-6 flex-shrink-0 text-indigo-200" aria-hidden="true" />
                  <a href="mailto:info@hotelshitalinn.com" className="ml-3">info@hotelshitalinn.com</a>
                </dd>
              </dl>
         
            </div>

            {/* Contact form */}

          
        
            <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12" id='mail'>
              <h3 className="text-lg font-medium text-gray-900">Send us a message</h3>
              <form onSubmit={handleSubmit} className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8" id='frm'>
                <div className="sm:col-span-2">
                  <label htmlFor="first-name" className="block text-sm font-medium text-gray-900">
                    Full name
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="first-name"
                      id="first-name"
                      autoComplete="given-name" required onChange={(e) =>  setName(e.target.value)}
                      className="block w-full rounded-md border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    />
                  </div>
                </div>
              
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-900">
                    Email
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email" onChange={(e) =>  setEmail(e.target.value)} required
                      className="block w-full rounded-md border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    />
                  </div>
                </div>
                <div>
                  <div className="flex justify-between">
                    <label htmlFor="phone" className="block text-sm font-medium text-gray-900">
                      Phone
                    </label>
                   
                  </div>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="phone"
                      id="phone"
                      autoComplete="tel" required
                      className="block w-full rounded-md border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      aria-describedby="phone-optional" onChange={(e) =>  setMobile(e.target.value)}
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label htmlFor="subject" className="block text-sm font-medium text-gray-900">
                    Subject
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="subject" required
                      id="subject" onChange={(e) =>  setSubject(e.target.value)}
                      className="block w-full rounded-md border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    />
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <div className="flex justify-between">
                    <label htmlFor="message" className="block text-sm font-medium text-gray-900">
                      Message
                    </label>
                    <span id="message-max" className="text-sm text-gray-500">
                      Max. 500 characters
                    </span>
                  </div>
                  <div className="mt-1">
                    <textarea
                      id="message"
                      name="message" required
                      rows={4} onChange={(e) =>  setMessage(e.target.value)}
                      className="block w-full rounded-md border-gray-300 py-3 px-4 text-gray-900 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                      aria-describedby="message-max"
                      defaultValue={''}
                    />
                  </div>
                  <label id='success' hidden="true">Message Sent Successfully.</label>

                </div>
               
                <div className="sm:col-span-2 sm:flex sm:justify-end">
                  <button
                  type="submit"
                  
                    className="mt-2 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-blue-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto"
                  >
                    Submit
                  </button>
               
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
        </>
    )


}