
import { Fragment, useEffect,useState } from 'react'
import { Popover, Transition,Disclosure, Menu } from '@headlessui/react'
import {
  ArrowPathIcon,
  Bars3Icon,
  ChartBarIcon,
  CursorArrowRaysIcon,
  DocumentChartBarIcon,
  ShieldCheckIcon,
  Squares2X2Icon,
  XMarkIcon,PlusIcon,
  BellIcon
} from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Link } from "react";
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import { redirect } from 'react-router';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  const solutions = [
    {
      name: 'Analytics',
      description: 'Get a better understanding of where your traffic is coming from.',
      href: '#',
      icon: ChartBarIcon,
    },
    {
      name: 'Engagement',
      description: 'Speak directly to your customers in a more meaningful way.',
      href: '#',
      icon: CursorArrowRaysIcon,
    },
    { name: 'Security', description: "Your customers' data will be safe and secure.", href: '#', icon: ShieldCheckIcon },
    {
      name: 'Integrations',
      description: "Connect with third-party tools that you're already using.",
      href: '#',
      icon: Squares2X2Icon,
    },
    {
      name: 'Automations',
      description: 'Build strategic funnels that will drive your customers to convert',
      href: '#',
      icon: ArrowPathIcon,
    },
    {
      name: 'Reports',
      description: 'Get detailed reports that will help you make more informed decisions ',
      href: '#',
      icon: DocumentChartBarIcon,
    },
  ]
  const resources = [
    {
      name: 'Help Center',
      description: 'Get all of your questions answered in our forums or contact support.',
      href: '#',
    },
    { name: 'Guides', description: 'Learn how to maximize our platform to get the most out of it.', href: '#' },
    { name: 'Events', description: 'See what meet-ups and other events we might be planning near you.', href: '#' },
    { name: 'Security', description: 'Understand how we take your privacy seriously.', href: '#' },
  ]

  function book()
  {
    window.open("http://book.hotelshitalinn.com/");
  }
 
export default function Nav() {
  const [currentv, setv] = useState("Home");

   
    const handleClickScroll = (props) => {
  
     

      if(window.location.pathname!="/")
      {
        window.location.href="/";
      }
      else
      {
      const element = document.getElementById(props);
      
      if (element) {
        // 👇 Will scroll smoothly to the top of the next section
        element.scrollIntoView({ behavior: 'smooth' });
        
      }
    }
    };
  
  
 
    return (
      <>
       <Disclosure as="nav" className="fixed top-0 left-0 right-0  z-50   bg-gray-700">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between p-6">
              <div className="flex">
                <div className="-ml-2 mr-2 flex items-center md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-white  hover:bg-blue-700  focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex  items-center">
                <a href="#">
            <span className="sr-only">Hotel Shital Inn</span>
            <img
              className="h-15 w-auto sm:h-17 items-center"
              src="./img/logo.png"
              alt=""
            />
          </a>
                 
                </div>
                <div className="hidden  md:ml-20 md:flex md:space-x-8">

                <button onClick={()=> handleClickScroll('home')} className="text-base text-base text-slate-300 hover:text-blue-400">HOME</button>
        <button onClick={()=> handleClickScroll('about')} className="text-base font-medium text-slate-300 hover:text-blue-400">ABOUT</button>

        <button onClick={()=> handleClickScroll('rooms')} className="text-base font-medium text-slate-300 hover:text-blue-400">ROOMS</button>
        <button onClick={()=> handleClickScroll('services')} className="text-base font-medium text-slate-300 hover:text-blue-400">SERVICES</button>
        <button onClick={()=> handleClickScroll('review')}  className="text-base font-medium text-slate-300 hover:text-blue-400"> REVIEWS</button>
        <button onClick={()=> handleClickScroll('city')}  className="text-base font-medium text-slate-300 hover:text-blue-400">AHMEDABAD</button>

        <button onClick={()=> handleClickScroll('contact')}  className="text-base font-medium text-slate-300 hover:text-blue-400">CONTACT</button>
         
                </div>
              </div>
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <button
                    onClick={book}
                    type="button"
                    className="relative inline-flex items-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  >
                    <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                    <span>Book Now</span>
                  </button>
                </div>
              
              </div>
            </div>
          </div>
          
         
          <Disclosure.Panel className="md:hidden">
        
            <div className="space-y-1 pt-2 pb-3">
              {/* Current: "bg-blue-50 border-blue-500 text-blue-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
              <a onClick={()=> handleClickScroll('home') } className="block border-l-4 border-transparent  py-2 pl-3 pr-4 text-base font-medium text-slate-100 hover:text-blue-400 sm:pl-5 sm:pr-6">HOME</a>
        <a onClick={()=> handleClickScroll('about')}  className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-slate-100  hover:text-blue-400 sm:pl-5 sm:pr-6">ABOUT</a>
        <a onClick={()=> handleClickScroll('rooms')}  className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-slate-100  hover:text-blue-400 sm:pl-5 sm:pr-6">ROOMS</a>
        <a onClick={()=> handleClickScroll('services')}  className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-slate-100  hover:text-blue-400 sm:pl-5 sm:pr-6">SERVICES</a>
        <a onClick={()=> handleClickScroll('review')}   className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-slate-100  hover:text-blue-400 sm:pl-5 sm:pr-6"> REVIEWS</a>
        <a onClick={()=> handleClickScroll('city')}   className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-slate-100  hover:text-blue-400 sm:pl-5 sm:pr-6">AHMEDABAD</a>
        <a onClick={()=> handleClickScroll('contact')}   className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-slate-100  hover:text-blue-400 sm:pl-5 sm:pr-6">CONTACT</a>
            </div>
            <div className="border-t border-gray-200 pt-4 pb-3">
             
            
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>

         

    </>
    )
}