
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
  ArrowPathIcon,
  Bars3Icon,
  ChartBarIcon,
  CursorArrowRaysIcon,
  DocumentChartBarIcon,
  ShieldCheckIcon,
  Squares2X2Icon,
  XMarkIcon,
  CloudArrowUpIcon,
  FingerPrintIcon, 
  LockClosedIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

import { FloatingWhatsApp } from 'react-floating-whatsapp'
import { Outlet, Link } from "react-router-dom";


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}




const navigation = {
  solutions: [
    { name: 'Marketing', href: '#' },
    { name: 'Analytics', href: '#' },
    { name: 'Commerce', href: '#' },
    { name: 'Insights', href: '#' },
  ],
  support: [
    { name: 'Pricing', href: '#' },
    { name: 'Documentation', href: '#' },
    { name: 'Guides', href: '#' },
    { name: 'API Status', href: '#' },
  ],
  company: [
    { name: 'About', href: '#' },
    { name: 'Blog', href: '#' },
    { name: 'Jobs', href: '#' },
    { name: 'Press', href: '#' },
    { name: 'Partners', href: '#' },
  ],
  legal: [
    { name: 'Claim', href: '#' },
    { name: 'Privacy', href: '#' },
    { name: 'Terms', href: '#' },
  ],
  social: [
    {
      name: 'Facebook',
      href: 'https://www.facebook.com/hotelshitalinn/',
      colour:'text-slate-100 hover:text-blue-400',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
   
    {
      name: 'Twitter',
      href: 'https://twitter.com/InnShital',
      colour:'text-slate-100 hover:text-sky-400',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      ),
    },

    {
      name: 'YouTube',
      href: 'https://www.youtube.com/channel/UCQmSN-PlNnvNSx7tZ6kNE6g',
      colour:'text-slate-100 hover:text-red-400',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
  ],
}
export default function Footer() {
    return (
      <>
       
        <footer className="bg-gray-700" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl py-12 px-6 md:flex md:items-center md:justify-between lg:px-8">
        <div className="flex justify-center space-x-6 md:order-2">
        <Link to={"/term"} className="text-center text-xs leading-5 text-slate-100"> 
    Terms and Condition
  </Link>
  <Link to={"/privacy"} className="text-center text-xs leading-5 text-slate-100"> 
  Privacy
  </Link>

          {/* <a href="/privacy" className="text-center text-xs leading-5 text-slate-100"> Privacy Policy </a> */}

        {navigation.social.map((item) => (
                <a key={item.name} href={item.href} className={item.colour}>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
        </div>
        <div className="mt-8 md:order-1 md:mt-0">
          <p className="text-center text-xs leading-5 text-slate-100">
          &copy; {new Date().getFullYear()} Hotel Shital Inn, Stay with us Feel Like Home. All rights reserved.
          </p>
        </div>
      </div>
      {/* <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
        <div className="xl:grid xl:grid-cols-2 xl:gap-8">
          <div className="space-y-8 ">
            <img
              className=""
              src="./img/logo.png"
              alt="Company name"
            />
            <p className="text-sm leading-6 text-slate-100">
            Hotel Shital Inn, we warmly welcome every guest, treating them like family. Our commitment to exceptional service ensures an unforgettable stay. Step into our sanctuary, where you can relax and rejuvenate. We aim to make your stay truly exceptional, offering a home away from home experience
                      </p>
            <div className="flex space-x-6">
              {navigation.social.map((item) => (
                <a key={item.name} href={item.href} className={item.colour}>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </div>
           





          </div>
          <div className="space-y-8 ">
           <h1 className=" text-slate-100">
                  Contact us
           </h1 >
            <p className="text-sm leading-6 text-slate-100">
            Hotel Shital Inn, we warmly welcome every guest, treating them like family. Our commitment to exceptional service ensures an unforgettable stay. Step into our sanctuary, where you can relax and rejuvenate. We aim to make your stay truly exceptional, offering a home away from home experience
                      </p>
            <div className="flex space-x-6">
              {navigation.social.map((item) => (
                <a key={item.name} href={item.href} className={item.colour}>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </div>
           





          </div>
         
        </div>

        
        <div className="mt-16 border-t border-gray-900/10 pt-8 sm:mt-20 lg:mt-24">
          <p className="text-xs leading-5 text-slate-100">&copy; {new Date().getFullYear()} Hotel Shital Inn, Stay with us Feel Like Home. All rights reserved. Maintained by Codeline</p>
        </div>
      </div> */}
      <FloatingWhatsApp
        phoneNumber="+919033667667"
        accountName="Hotel Shital Inn"
        avatar="./img/-604_17.webp"
        allowEsc
        
        notification
        notificationSound/>
    </footer>
      </>)
}
