import {
  danielImg,
  jonathanImg,
  jeanetteImg,
  patrickImg,
  kiraImg,
} from './assets/img/images'

export const testimonials = {
  testimonialOne: {
    name: 'WISHULADA PANTHANUVONG',
    img: danielImg,
    text: `“This is my first visit in India. But made me very impressed because of this hotel is near a lot of restaurants and street food..`,
    testimonial: `"Convenient transportation.
    Moreover..
    This Hotel is
    Excellent Facilities.
    Excellent Housekeeping.
    Excellent Receptionist
    Excellent Bellboy.
    All EXCELLENT SERVICE 🙏❤️
    100star✨️⭐️⭐️.”`,
  },
  testimonialTwo: {
    name: 'Dr. Ravirajsinh Vaghela',
    img: jonathanImg,
    text: 'Best location near vastrapur lake alpha one mall',
    testimonial: `“Best location near vastrapur lake alpha one mall and in budget room with Good infrastructure & furniture with good room service. So value for money”`,
  },
  testimonialThree: {
    name: 'Shraddha Doshi',
    img: jeanetteImg,
    text: 'It is a really very nice hotel',
    testimonial: `“ It's a really very nice hotel for stay with good services and cooperative staff quality of food is also good.. Whenever I go to ahmedabad I prefer to stay there only.. At least visit once. ”`,
  },
  testimonialFour: {
    name: 'Rakesh Botadkar',
    img: patrickImg,
    text: `Really clean and nice hotel.`,
    testimonial: `"Really clean and nice hotel. Staff is very understanding. We had a few rooms booked for guests for a wedding ceremony. They all were very well looked after by the hotel staff. Breakfast is very nice. Overall. Very good experience. No complaints. Location also very good. Right near Vastrapur Lake. And Ahmedabad One Mall."`,
  },
  testimonialFive: {
    name: 'Rajeev Mishra',
    img: kiraImg,
    text: 'The hotel is located in prime location and has all the necessary facilities.',
    testimonial: `“The hotel is located in prime location and has all the necessary facilities. hotel staff is good and timely services are provided. Rooms are also great with television, air conditioning and bathroom supplies. Lunch, Dinner and breakfast can be ordered directly to the room via call. All Covid mandatory guidelines are also being followed completely.
    ”`,
  },
}
