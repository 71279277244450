import React from 'react'
import GoogleIcon from '@mui/icons-material/Google';
import StarIcon from '@mui/icons-material/Star';
export const CardHeader = ({ img, name }) => {
  return (
    <div className='flex items-center gap-x-4 mb-4'>
      <figcaption>
        <h3>{name}</h3>
        <StarIcon className="h-6 w-6 text-yellow-400" aria-hidden="true" />
        <StarIcon className="h-6 w-6 text-yellow-400" aria-hidden="true" />
        <StarIcon className="h-6 w-6 text-yellow-400" aria-hidden="true" />
        <StarIcon className="h-6 w-6 text-yellow-400" aria-hidden="true" />
        <StarIcon className="h-6 w-6 text-yellow-400" aria-hidden="true" />


        <p className='opacity-50'> Google Review</p>
      </figcaption>
    </div>
  )
}

export const CardText = ({ text }) => {
  return <p className='font-semibold text-xl mb-4'>{text}</p>
}

export const CardTestimonial = ({ testimonial }) => {
  return (
    <blockquote>
      <p className='opacity-70'>{testimonial}</p>
    </blockquote>
  )
}
