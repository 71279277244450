import { Fragment,useState,Link } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
  ArrowPathIcon,
  Bars3Icon,
  ChartBarIcon,
  CursorArrowRaysIcon,
  DocumentChartBarIcon,
  ShieldCheckIcon,
  Squares2X2Icon,
  XMarkIcon,
  CloudArrowUpIcon,
  FingerPrintIcon, 
  LockClosedIcon,
  WifiIcon,EnvelopeIcon, PhoneIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import Nav from "./nav";
import Footer from "./footer";
import LocalLaundryServiceIcon from '@mui/icons-material/LocalLaundryService';import RoomServiceIcon from '@mui/icons-material/RoomService';
import LocalTaxiIcon from '@mui/icons-material/LocalTaxi';
import FreeBreakfastIcon from '@mui/icons-material/FreeBreakfast';
import ModeOfTravelIcon from '@mui/icons-material/ModeOfTravel';
import {
  CardHeader,
  CardTestimonial,
  CardText,
} from './CardComponents'
import { testimonials } from './testimonials'
import patternQuotation from './assets/svg/bg-pattern-quotation.svg'
import { TypeAnimation } from 'react-type-animation';
import Example2 from "./nav2";
import FormHandling from "./FormHandling";
import {Helmet} from "react-helmet";



const features = [
  {
    name: 'Stay Connected, Without Limits: Unleash the Power of Unlimited Device WiFi at Hotel Shital Inn!',
    description:
      "With our cutting-edge WiFi technology, we offer lightning-fast internet speeds that will satisfy even the most demanding connectivity needs. Whether you're streaming your favorite shows, engaging in video conferences, or simply browsing the web, our high-speed WiFi ensures a seamless and lag-free online experience throughout your stay.",
    icon: WifiIcon,
  },
  {
    name: 'Experience effortless freshness: Let our laundry service take care of your garments with care and precision',
    description:
      'Discover the convenience of our top-notch laundry service at our hotel. Say goodbye to the hassle of washing clothes while traveling and let us take care of your laundry needs. Our dedicated team of professionals will handle your garments with care, ensuring they are cleaned, pressed, and returned to you promptly. Enjoy your stay without worrying about laundry, and step out in fresh, crisp attire every day.',
    icon: LocalLaundryServiceIcon,
  },
  {
    name: 'Indulge in personalized luxury with In-Room Dining: A delectable experience delivered to your door."',
    description:
      'Indulge in the ultimate convenience and luxury of In-Room Dining at our hotel. Delight in a wide array of delectable meals and beverages, meticulously prepared by our talented culinary team and delivered straight to your room. Whether you crave a delicious breakfast, a sumptuous dinner, or a late-night snack, our In-Room Dining service ensures a memorable dining experience without ever leaving the comfort of your own space. Sit back, relax, and let us cater to your culinary desires, right at your doorstep.',
    icon: RoomServiceIcon,
  },
  {
    name: 'Arrive in style, depart with ease: Experience seamless travel with our Airport Pickup and Drop Service.',
    description:
      'Experience seamless transportation with our Airport Pickup and Drop Service at our hotel. We understand the importance of a smooth arrival and departure, which is why we provide reliable and efficient transportation to and from the airport.With our Airport Pickup and Drop Service, you can focus on enjoying your trip, knowing that reliable and punctual transportation is just a reservation away. Start and end your journey with ease, leaving the hassle of transportation to us.',
    icon: LocalTaxiIcon,
  },
  {
    name: 'Start your day with a delightful treat: Indulge in our complimentary breakfast* T&C apply , exclusively for our valued guests',
    description:
      'Kickstart your mornings with a delightful treat at our hotel, where we proudly offer a complimentary breakfast for our esteemed guests. Wake up to a tempting spread of fresh and flavorful options, carefully curated to satisfy every palate.Our commitment to providing a complimentary breakfast reflects our dedication to ensuring a delightful start to your day. Enjoy the convenience, variety, and quality that our breakfast offerings bring, all on us, as our way of enhancing your stay and leaving you with a truly satisfying experience.',
    icon: FreeBreakfastIcon,
  },
  {
    name: "Unlock extraordinary journeys: Explore with confidence at our hotel's Travel Desk",
    description:
      'Embark on a seamless journey with the assistance of our dedicated Travel Desk at our hotel. We understand that exploring a new destination can be both exciting and overwhelming, which is why we offer comprehensive travel services to cater to all your needs.Discover the best of the destination with ease, knowing that our Travel Desk is dedicated to making your stay hassle-free and enjoyable. Let us handle the details while you focus on creating lasting memories during your exploration. Trust our expertise to guide you towards unforgettable experiences and unforgettable destinations.',
    icon: ModeOfTravelIcon,
  },
]
const solutions = [
  {
    name: 'Analytics',
    description: 'Get a better understanding of where your traffic is coming from.',
    href: '#',
    icon: ChartBarIcon,
  },
  {
    name: 'Engagement',
    description: 'Speak directly to your customers in a more meaningful way.',
    href: '#',
    icon: CursorArrowRaysIcon,
  },
  { name: 'Security', description: "Your customers' data will be safe and secure.", href: '#', icon: ShieldCheckIcon },
  {
    name: 'Integrations',
    description: "Connect with third-party tools that you're already using.",
    href: '#',
    icon: Squares2X2Icon,
  },
  {
    name: 'Automations',
    description: 'Build strategic funnels that will drive your customers to convert',
    href: '#',
    icon: ArrowPathIcon,
  },
  {
    name: 'Reports',
    description: 'Get detailed reports that will help you make more informed decisions ',
    href: '#',
    icon: DocumentChartBarIcon,
  },
]
const resources = [
  {
    name: 'Help Center',
    description: 'Get all of your questions answered in our forums or contact support.',
    href: '#',
  },
  { name: 'Guides', description: 'Learn how to maximize our platform to get the most out of it.', href: '#' },
  { name: 'Events', description: 'See what meet-ups and other events we might be planning near you.', href: '#' },
  { name: 'Security', description: 'Understand how we take your privacy seriously.', href: '#' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


const posts = [
  {
    title: 'Confrence Room',
    href: '#',
    category: { name: 'Confrence', href: '#' },
    description:
      'Our gathering rooms on lease in Ahmedabad city are comprehensive of web conferencing support, TV, ergonomically planned seats and so forth.',
    date: 'Mar 16, 2020',
    datetime: '2020-03-16',
    imageUrl:
      './img/589_02.webp',
   
  },
  {
    title: 'Deluxe Double Bed Room',
    href: '#',
    category: { name: 'Room', href: '#' },
    description:
      'Designed for the modern traveler, the deluxe twin space erases all of your temporary states away with its distinct comforts and luxuries. Plus, it’s all the amenities and facilities that build your business trip stress-free and eminen',
    date: 'Mar 10, 2020',
    datetime: '2020-03-10',
    imageUrl:
      './img/604_06.webp',
  
  },
  {
    title: 'Deluxe Twin Bed',
    href: '#',
    category: { name: 'Room', href: '#' },
    description:
      'Designed for the modern traveler, the deluxe twin space erases all of your temporary states away with its distinct comforts and luxuries. Plus, it’s all the amenities and facilities that build your business trip stress-free and experience',
    date: 'Feb 12, 2020',
    datetime: '2020-02-12',
    imageUrl:
      './img/604_03.webp',
  
  },
]


const navigation = {
  solutions: [
    { name: 'Marketing', href: '#' },
    { name: 'Analytics', href: '#' },
    { name: 'Commerce', href: '#' },
    { name: 'Insights', href: '#' },
  ],
  support: [
    { name: 'Pricing', href: '#' },
    { name: 'Documentation', href: '#' },
    { name: 'Guides', href: '#' },
    { name: 'API Status', href: '#' },
  ],
  company: [
    { name: 'About', href: '#' },
    { name: 'Blog', href: '#' },
    { name: 'Jobs', href: '#' },
    { name: 'Press', href: '#' },
    { name: 'Partners', href: '#' },
  ],
  legal: [
    { name: 'Claim', href: '#' },
    { name: 'Privacy', href: '#' },
    { name: 'Terms', href: '#' },
  ],
  social: [
    {
      name: 'Facebook',
      href: '#',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: 'Instagram',
      href: '#',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: 'Twitter',
      href: '#',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      ),
    },
    {
      name: 'GitHub',
      href: '#',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: 'YouTube',
      href: '#',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
  ],
}
function book()
{
  window.open("http://book.hotelshitalinn.com/");
}



export default function Example() {
  return (
    <>
     <Helmet>
            <title>Hotel Shital Inn Ahmedabad - Your Comfortable Stay in Vastrapur | Stay with us Feel Like Home</title>
            <meta name="description" content="Discover the ultimate comfort at Hotel Shital Inn in Vastrapur, Ahmedabad. We offer top-notch amenities, convenient location, and warm hospitality for a memorable stay." />
            <meta property="og:title" content="Hotel Shital Inn Ahmedabad - Your Comfortable Stay in Vastrapur | Stay with us Feel Like Home" />
            <meta name="keywords" content="Ahmedabad hotel, Vastrapur accommodation, best hotels in Ahmedabad, comfortable stay Vastrapur, affordable hotel Ahmedabad"/>
            <meta name="viewport" content="width=device-width, initial-scale=1.0"/>

          </Helmet>
   <Nav>

   </Nav>

   <div className="pb-16" id="home">

   </div>
   <div className="relative isolate overflow-hidden bg-gray-900 mt-100">
      <img
        src="/img/welcome.webp"
        alt=""
        className="absolute inset-0 -z-10 h-screen w-full object-cover"
      />
      <div className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]">
        <svg
          className="relative left-[calc(50%-11rem)] -z-10 h-[21.1875rem] max-w-none -translate-x-1/2 rotate-[30deg] sm:left-[calc(50%-30rem)] sm:h-[42.375rem]"
          viewBox="0 0 1155 678"
        >
          <path
            fill="url(#45de2b6b-92d5-4d68-a6a0-9b9b2abad533)"
            fillOpacity=".2"
            d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
          />
          <defs>
            <linearGradient
              id="45de2b6b-92d5-4d68-a6a0-9b9b2abad533"
              x1="1155.49"
              x2="-78.208"
              y1=".177"
              y2="474.645"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#9089FC" />
              <stop offset={1} stopColor="#FF80B5" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div className="px-6 lg:px-8">
   
      
        <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
          <div className="hidden sm:mb-8 sm:flex sm:justify-center">
            <div className=" relative rounded-full py-1 px-3 text-sm leading-6 text-white ring-1 ring-white/10 hover:ring-white/20">
              Book Now. At an Exclusive Rate{' '}
            </div>
          </div>
          <div className="text-center">
          <TypeAnimation
  sequence={[
    // Same substring at the start will only be typed once, initially
    'Hello, Welcome to',
    1000,
   
    'नमस्ते, आपका स्वागत है',
    1000,
    'ਸਤਿ ਸ੍ਰੀ ਅਕਾਲ, ਤੁਹਾਡਾ ਸੁਆਗਤ ਹੈ',
    1000,
    '你好',
    1000,
  ]}
  speed={20}
  
  className="text-4xl font-bold tracking-tight text-white sm:text-6xl"
  repeat={Infinity}
/>
            <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
               Hotel Shital Inn,<br></br> 
              Stay with us feel like home
            </h1>
            {/* <p className="mt-6 text-lg leading-8 text-gray-300">
              Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo. Elit sunt amet
              fugiat veniam occaecat fugiat aliqua.
            </p> */}
         
          </div>
        </div>
      </div>
      <div className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]">
        <svg
          className="relative left-[calc(50%+3rem)] h-[21.1875rem] max-w-none -translate-x-1/2 sm:left-[calc(50%+36rem)] sm:h-[42.375rem]"
          viewBox="0 0 1155 678"
        >
          <path
            fill="url(#ecb5b0c9-546c-4772-8c71-4d3f06d544bc)"
            fillOpacity=".2"
            d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
          />
          <defs>
            <linearGradient
              id="ecb5b0c9-546c-4772-8c71-4d3f06d544bc"
              x1="1155.49"
              x2="-78.208"
              y1=".177"
              y2="474.645"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#9089FC" />
              <stop offset={1} stopColor="#FF80B5" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </div>
   

    <div className="relative  bg-gray-700" id="about">
      <div className="mx-auto max-w-7xl lg:grid lg:grid-cols-12 lg:gap-x-8 lg:px-8">
        <div className="px-6 pt-10 pb-24 sm:pb-32 lg:col-span-7 lg:px-0 lg:pt-48 lg:pb-56 xl:col-span-6">
       
          <div className="mx-auto max-w-2xl lg:mx-0">
            
            <h1 className= "animate-typing mt-24 text-4xl font-bold tracking-tight text-white sm:mt-10 sm:text-6xl">
              Hotel Shital Inn <br/>
              Stay with us. Feel Like Home
            </h1>
            <p className="mt-6 text-lg leading-8 text-white">
            Welcome to Hotel Shital Inn, where every guest is embraced with warm hospitality and treated like family. With our heartfelt commitment to exceptional service and attention to detail, we strive to make your stay an unforgettable experience. From the moment you step through our doors, we aim to create a haven where you can relax, rejuvenate, and truly feel at home.


            </p>
            <div className="mt-10 flex items-center gap-x-6">
              <a
                onClick={book}
                className="rounded-md 
bg-blue-600 px-3.5 py-1.5 text-base font-semibold leading-7 text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              >
                Book Now
              </a>
              
            </div>
          </div>
        </div>
        <div className="relative lg:col-span-5 lg:-mr-8 xl:absolute xl:inset-0 xl:left-1/2 xl:mr-0">
          <img
            className="aspect-[3/2] w-full bg-gray-50 object-cover lg:absolute lg:inset-0 lg:aspect-auto lg:h-full"
            src="/img/-604_19.webp"
            alt=""
          />
        </div>
      </div>
    </div>

   
    <div className="pb-5" id="rooms">

</div>

    <div className="relative bg-gray-50 px-6 pt-16 pb-20 lg:px-8 lg:pt-24 lg:pb-28" id="rooms">





      <div className="absolute relative inset-0 bg-gray-700"  >
        <div className="h-1/3 bg-white sm:h-2/3" />
      </div>
      <div className="relative mx-auto max-w-7xl" >
        <div className="text-center" >
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Our Rooms And Services</h2>
          <p className="mx-auto mt-3 max-w-2xl text-xl text-gray-500 sm:mt-4">
          Experience Comfort and Luxury in Our Exquisite Rooms and Unparalleled Services
          </p>
        </div>
        <div className="mx-auto mt-12 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3">
          {posts.map((post) => (
            <div key={post.title} className="flex flex-col overflow-hidden rounded-lg shadow-lg">
              <div className="flex-shrink-0">
                <img className="h-48 w-full object-cover" src={post.imageUrl} alt="" />
              </div>
              <div className="flex flex-1 flex-col justify-between bg-white p-6">
                <div className="flex-1">
                  <p className="text-sm font-medium text-blue-600">
                    <a href={post.category.href} className="hover:underline">
                      {post.category.name}
                    </a>
                  </p>
                  <a href={post.href} className="mt-2 block">
                    <p className="text-xl font-semibold text-gray-900">{post.title}</p>
                    <p className="mt-3 text-base text-gray-500">{post.description}</p>
                  </a>
                </div>
               
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>

    <div className="bg-white py-24 sm:py-32" id="services">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center" >
          <h2 className="text-lg font-semibold leading-8 tracking-tight text-blue-600">During your stay</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Indulge in Unforgettable Moments with Our Exclusive Services During Your Stay
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
          During your stay at our hotel, we are committed to providing you with an exceptional experience through our comprehensive range of services. From the moment you check-in, our attentive staff will be at your service, ensuring that your every need is met with efficiency and professionalism.


          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-y-10 gap-x-8 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-16">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div className="absolute top-0 left-0 flex h-10 w-10 items-center justify-center rounded-lg 
bg-blue-600">
                    <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>

    <div className="bg-gray-50" id="city">
      <div className="mx-auto max-w-7xl py-16 px-6 sm:py-24 lg:px-8">
        <div className="text-center">
          <h2 className="text-lg font-semibold text-blue-600">Ahmedabad</h2>
          <p className="mt-1 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl">
          Step into history, embrace the legacy: Discover the wonders of India's First World Heritage City
          </p>
          
        </div>
      </div>
    </div>


    <section className="pt-20 lg:pt-[120px] pb-12 lg:pb-[90px] pb-12 lg:pb-[90px] overflow-hidden">
        <div className="container">
          <div className="flex flex-wrap items-center justify-center -mx-4">
            <div className="w-full px-4 lg:w-6/12">
              <div className="flex items-center -mx-3 sm:-mx-4">
              <div className="w-full px-3 sm:px-4 xl:w-1/2">
                  
                  <div className="relative z-10 my-4">
                    <img
                      src="./img/sidi_sayed.webp"
                      alt=""
                      className="w-full rounded-2xl"
                    />
                    </div>
                  
                  
                  
                  
                  
                </div>
                <div className="w-full px-3 sm:px-4 xl:w-1/2">
                  
                  <div className="py-3 sm:py-4">
                    <img
                      src="./img/hathising.webp"
                      alt=""
                      className="w-full rounded-2xl"
                    />
                  </div>
                  
                  <div className="py-3 sm:py-4">
                    <img
                      src="./img/textile.webp"
                      alt=""
                      className="w-full rounded-2xl"
                    />
                  </div>
                  
                </div>
              
                
              </div>
            </div>
            <div className="w-full px-4 lg:w-1/2 xl:w-5/12 ">
              <div className="mt-10 lg:mt-0">
                
                <h2 className="mb-8 text-3xl font-bold text-dark sm:text-4xl">
                Discover the wonders of India's First World Heritage City, Ahmedabad.
                </h2>
                <p className="mb-8 text-base text-body-color">
                Steeped in history and culture, this captivating city invites you to explore its architectural marvels, vibrant markets, and rich heritage.

Wander through the narrow lanes of the old city, where intricately carved havelis and ancient temples tell tales of a bygone era. Admire the stunning mosques, such as the Jama Masjid and Sidi Saiyyed Mosque, showcasing exquisite craftsmanship and architectural beauty.

Marvel at the stepwells, such as Adalaj Stepwell and Dada Hari Vav, with their intricate designs and impressive structures.

Immerse yourself in the vibrant markets, where traditional textiles, embroidered garments, and handicrafts are a feast for the eyes. Indulge in the flavors of Gujarati cuisine, from street food delights to the wholesome goodness of a Gujarati thali.

Ahmedabad is also a city of festivals, with the International Kite Festival and Navratri celebrations adding color and joy to the cultural fabric.

Experience the essence of Ahmedabad as you delve into its history, immerse yourself in its traditions, and witness its vibrant spirit. This First World Heritage City awaits, ready to enchant you with its timeless allure.





                </p>
                <a
                  href="/#"
                  className="inline-flex items-center justify-center px-10 py-4 text-base font-normal text-center text-white rounded-lg bg-primary hover:bg-opacity-90 lg:px-8 xl:px-10"
                >
                  Get Started
                </a>
              </div>
            </div>
            
          </div>
        </div>
      </section>



      
      {/* <div className="min-w-screen min-h-screen bg-gray-50 flex items-center justify-center py-5">
        <div className="w-full bg-white border-t border-b border-gray-200 px-5 py-16 md:py-24 text-gray-800">
          <div className="w-full max-w-6xl mx-auto">
            <div className="text-center max-w-xl mx-auto">
              <h1 className="text-6xl md:text-7xl font-bold mb-5 text-gray-600">What our guest <br />are saying.</h1>
              <h3 className="text-xl mb-5 font-light">Lorem ipsum dolor sit amet consectetur adipisicing elit.</h3>
              <div className="text-center mb-10">
                <span className="inline-block w-1 h-1 rounded-full bg-blue-500 ml-1" />
                <span className="inline-block w-3 h-1 rounded-full bg-blue-500 ml-1" />
                <span className="inline-block w-40 h-1 rounded-full bg-blue-500" />
                <span className="inline-block w-3 h-1 rounded-full bg-blue-500 ml-1" />
                <span className="inline-block w-1 h-1 rounded-full bg-blue-500 ml-1" />
              </div>
            </div>
            <div className="-mx-3 md:flex items-start">
              <div className="px-3 md:w-1/3">
                <div className="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6">
                  <div className="w-full flex mb-4 items-center">
                    <div className="overflow-hidden rounded-full w-10 h-10 bg-gray-50 border border-gray-200">
                      <img src="https://i.pravatar.cc/100?img=1" alt="" />
                    </div>
                    <div className="flex-grow pl-3">
                      <h6 className="font-bold text-sm uppercase text-gray-600">Kenzie Edgar.</h6>
                    </div>
                  </div>
                  <div className="w-full">
                    <p className="text-sm leading-tight"><span className="text-lg leading-none italic font-bold text-gray-400 mr-1">"</span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos sunt ratione dolor exercitationem minima quas itaque saepe quasi architecto vel! Accusantium, vero sint recusandae cum tempora nemo commodi soluta deleniti.<span className="text-lg leading-none italic font-bold text-gray-400 ml-1">"</span></p>
                  </div>
                </div>
                <div className="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6">
                  <div className="w-full flex mb-4 items-center">
                    <div className="overflow-hidden rounded-full w-10 h-10 bg-gray-50 border border-gray-200">
                      <img src="https://i.pravatar.cc/100?img=2" alt="" />
                    </div>
                    <div className="flex-grow pl-3">
                      <h6 className="font-bold text-sm uppercase text-gray-600">Stevie Tifft.</h6>
                    </div>
                  </div>
                  <div className="w-full">
                    <p className="text-sm leading-tight"><span className="text-lg leading-none italic font-bold text-gray-400 mr-1">"</span>Lorem ipsum, dolor sit amet, consectetur adipisicing elit. Dolore quod necessitatibus, labore sapiente, est, dignissimos ullam error ipsam sint quam tempora vel.<span className="text-lg leading-none italic font-bold text-gray-400 ml-1">"</span></p>
                  </div>
                </div>
              </div>
              <div className="px-3 md:w-1/3">
                <div className="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6">
                  <div className="w-full flex mb-4 items-center">
                    <div className="overflow-hidden rounded-full w-10 h-10 bg-gray-50 border border-gray-200">
                      <img src="https://i.pravatar.cc/100?img=3" alt="" />
                    </div>
                    <div className="flex-grow pl-3">
                      <h6 className="font-bold text-sm uppercase text-gray-600">Tommie Ewart.</h6>
                    </div>
                  </div>
                  <div className="w-full">
                    <p className="text-sm leading-tight"><span className="text-lg leading-none italic font-bold text-gray-400 mr-1">"</span>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vitae, obcaecati ullam excepturi dicta error deleniti sequi.<span className="text-lg leading-none italic font-bold text-gray-400 ml-1">"</span></p>
                  </div>
                </div>
                <div className="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6">
                  <div className="w-full flex mb-4 items-center">
                    <div className="overflow-hidden rounded-full w-10 h-10 bg-gray-50 border border-gray-200">
                      <img src="https://i.pravatar.cc/100?img=4" alt="" />
                    </div>
                    <div className="flex-grow pl-3">
                      <h6 className="font-bold text-sm uppercase text-gray-600">Charlie Howse.</h6>
                    </div>
                  </div>
                  <div className="w-full">
                    <p className="text-sm leading-tight"><span className="text-lg leading-none italic font-bold text-gray-400 mr-1">"</span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto inventore voluptatum nostrum atque, corrupti, vitae esse id accusamus dignissimos neque reprehenderit natus, hic sequi itaque dicta nisi voluptatem! Culpa, iusto.<span className="text-lg leading-none italic font-bold text-gray-400 ml-1">"</span></p>
                  </div>
                </div>
              </div>
              <div className="px-3 md:w-1/3">
                <div className="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6">
                  <div className="w-full flex mb-4 items-center">
                    <div className="overflow-hidden rounded-full w-10 h-10 bg-gray-50 border border-gray-200">
                      <img src="https://i.pravatar.cc/100?img=5" alt="" />
                    </div>
                    <div className="flex-grow pl-3">
                      <h6 className="font-bold text-sm uppercase text-gray-600">Nevada Herbertson.</h6>
                    </div>
                  </div>
                  <div className="w-full">
                    <p className="text-sm leading-tight"><span className="text-lg leading-none italic font-bold text-gray-400 mr-1">"</span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis, voluptatem porro obcaecati dicta, quibusdam sunt ipsum, laboriosam nostrum facere exercitationem pariatur deserunt tempora molestiae assumenda nesciunt alias eius? Illo, autem!<span className="text-lg leading-none italic font-bold text-gray-400 ml-1">"</span></p>
                  </div>
                </div>
                <div className="w-full mx-auto rounded-lg bg-white border border-gray-200 p-5 text-gray-800 font-light mb-6">
                  <div className="w-full flex mb-4 items-center">
                    <div className="overflow-hidden rounded-full w-10 h-10 bg-gray-50 border border-gray-200">
                      <img src="https://i.pravatar.cc/100?img=6" alt="" />
                    </div>
                    <div className="flex-grow pl-3">
                      <h6 className="font-bold text-sm uppercase text-gray-600">Kris Stanton.</h6>
                    </div>
                  </div>
                  <div className="w-full">
                    <p className="text-sm leading-tight"><span className="text-lg leading-none italic font-bold text-gray-400 mr-1">"</span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem iusto, explicabo, cupiditate quas totam!<span className="text-lg leading-none italic font-bold text-gray-400 ml-1">"</span></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

<div className="bg-gray-50" id="review">
      <div className="mx-auto max-w-7xl py-16 px-6 sm:py-24 lg:px-8">
        <div className="text-center">
          <h2 className="text-lg font-semibold text-blue-600">Ahmedabad</h2>
          <p className="mt-1 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl">
          Unforgettable Experiences, Shared by Our Guests: Hear What They Love About Hotel Shital Inn!
          </p>
          
        </div>
      </div>
    </div>

<section
      className='font-barlow mx-6 my-16 text-white grid gap-6
      md:grid-cols-2 md:mx-10 xl:grid-cols-4 xl:mx-auto xl:max-w-6xl sm:grid-cols-1'
    >
      <figure
        className=' shadow-md rounded-lg relative z-10 p-8
        md:hover:scale-105 duration-300 md:col-span-2  bg-violet-700 '
      >
        <img
          className='absolute top-0 right-8 w-28 -z-10 xl:right-20'
          src={patternQuotation}
          alt='Background svg'
        />
        <CardHeader
          
          name={testimonials.testimonialOne.name}
        />
        <CardText text={testimonials.testimonialOne.text} />
        <CardTestimonial
          testimonial={testimonials.testimonialOne.testimonial}
        />
      </figure>
      <figure
        className='card2 shadow-md rounded-lg p-8
        md:hover:scale-105 duration-300 bg-emerald-800'
      >
        <CardHeader
          
          name={testimonials.testimonialTwo.name}
        />
        <CardText text={testimonials.testimonialTwo.text} />
        <CardTestimonial
          testimonial={testimonials.testimonialTwo.testimonial}
        />
      </figure>
      <figure
        className='card3 shadow-md rounded-lg p-8
        md:hover:scale-105 duration-300 bg-cyan-500'
      >
        <CardHeader
          
          name={testimonials.testimonialThree.name}
        />
        <CardText text={testimonials.testimonialThree.text} />
        <CardTestimonial
          testimonial={testimonials.testimonialThree.testimonial}
        />
      </figure>
      <figure
        className='card4 shadow-md rounded-lg p-8
        md:col-span-2 md:hover:scale-105 duration-300 bg-rose-700'
      >
        <CardHeader
          
          name={testimonials.testimonialFour.name}
        />
        <CardText text={testimonials.testimonialFour.text} />
        <CardTestimonial
          testimonial={testimonials.testimonialFour.testimonial}
        />
      </figure>
      <figure
        className='card5 shadow-md rounded-lg p-8
        md:hover:scale-105 duration-300 md:col-span-2
        xl:row-start-1 xl:row-end-3 xl:col-span-1 xl:col-start-4 bg-orange-700'
      >
        <CardHeader
          
          name={testimonials.testimonialFive.name}
        />
        <CardText text={testimonials.testimonialFive.text} />
        <CardTestimonial
          testimonial={testimonials.testimonialFive.testimonial}
        />
      </figure>
    </section>

    <div className="bg-gray-50" id="contact">
      <div className="mx-auto max-w-7xl py-16 px-6 sm:py-24 lg:px-8">
        <div className="text-center">
          <h2 className="text-lg font-semibold text-blue-600">Ahmedabad</h2>
          <p className="mt-1 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl lg:text-5xl">
          Connecting with Excellence: Contact Hotel Shital Inn for Unparalleled Hospitality and Service
          </p>
          
        </div>
      </div>
    </div>
      <FormHandling>

        
      </FormHandling>
      
     
    <Footer>

    </Footer>

    </>
  )
}
